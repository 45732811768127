import { useMediaQuery } from '@vueuse/core'

export const useScreenSize = () => {
  const isPhoneOnly = useMediaQuery('(max-width: 500px)')
  const isLandscapeDown = useMediaQuery('(max-width: 992px)')
  const isTabletPortraitDown = useMediaQuery('(max-width: 768px)')

  return {
    isPhoneOnly,
    isLandscapeDown,
    isTabletPortraitDown,
  }
}
